import React, { useState, useEffect, useContext } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import usePropertySortFn from '@hooks/usePropertySortFn'
import AppContext from '@contexts/AppContext';
import BidderDashboardBidsTable from './BidderDashboardBidsTable';
import { getMyBids } from '@services/product';

export default function BidderDashboardBidsPage({ userData: { bidsMaxPrice, bidderAlias } }) {
    const { agencyData: { agencyId } } = useContext(AppContext);
    const [biddedProperties, setBiddedProperties] = useState(null);
    const [loading, setLoading] = useState(false);

    const sortFn = usePropertySortFn()

    useEffect(() => {
        const fn = async () => {
            setLoading(true);
            const biddedList = await getMyBids(agencyId)
            setBiddedProperties(biddedList.sort(sortFn))
            setLoading(false);
        }
        fn()
    }, []);

    return (
        <>
            <div className="flex flex-col items-center sm:block">
                <h1 className="page-header">Property Bids</h1>
            </div>
            {
                loading ? (
                    <div className="mt-16 flex flex-col items-center flex-grow justify-center">
                        <CircularProgress size="5rem" color="secondary" />  
                        <h2 className="font-primary font-bold text-3xl text-center mt-3">Loading bidded properties...</h2>
                    </div>
                ) : (
                    biddedProperties && (
                        biddedProperties.length ? (
                            <BidderDashboardBidsTable biddedProperties={biddedProperties} bidsMaxPrice={bidsMaxPrice} bidderAlias={bidderAlias} />
                        ): (
                            <div className="mt-16">
                                <h2 className="font-bold text-primary-agency text-4xl">You're currently not bidding on any properties!</h2>
                            </div>
                        )
                    )
                )
            }
        </>
    )
}