import React, { useState } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import DescriptionIcon from '@material-ui/icons/Description';

import PropertyPageBidPriceSection from './PropertyPageBidPriceSection';
import PropertyPageBiddingPositionSection from './PropertyPageBiddingPositionSection';
import PropertyPageBidForm from './PropertyPageBidForm';
import BidHistorySection from '@components/BidHistorySection';
import ModalLayout from '@components/ModalLayout';
import DocumentView from '@components/DocumentView';

/* 
    TODO Make sure to include 'Help on Bidding' link.
*/
export default function PropertyPageBidColumn({ userType, bidLoading, reserveStatus, bidderAlias, stripePromise, assetDocuments, bidHistory, productStatus, publishDate, bidPrice, bidStartPrice, formattedBidPrice, formattedBidStartPrice, bidsMaxPrice, bidderStatus, productId, isVerified, isFullyRegistered, isCardComplete, isOutbid, bidEndTime, handleBid, handleBidEnd }) {
    const [docsOpen, setDocsOpen] = useState(false);
    const [helpOpen, setHelpOpen] = useState(false);

    const showBidPriceSection = productStatus !== 'Withdrawn';
    const showBiddingPositionSection = productStatus === 'Live' && !!isFullyRegistered;
    const showBidForm = userType !== 'agent' && productStatus === 'Live';
    const showPlaceOfferMessage = productStatus === 'Pre-Market' || productStatus === 'Scheduled Pre-Market';
    const showLegalPack = userType && isVerified && (productStatus === 'Pre-Market' || productStatus === 'Scheduled Pre-Market' || productStatus === 'Live');
    const showHelpBidding = productStatus !== 'Withdrawn';
    const showBidHistory = productStatus === 'Live' || productStatus === 'Sold' || productStatus === 'Ended';

    return (
        <section className="lg:w-4/10 property-page-1300:w-3/10 border-l-2 border-r-2 border-light-gray">
            {
                showBidPriceSection && (
                    <PropertyPageBidPriceSection 
                    productStatus={productStatus} 
                    bidPrice={bidPrice} 
                    bidStartPrice={bidStartPrice} 
                    formattedBidPrice={formattedBidPrice}
                    bidEndTime={bidEndTime}
                    reserveStatus={reserveStatus} />
                )
            }
            {
                showBiddingPositionSection && (
                    <PropertyPageBiddingPositionSection 
                    productStatus={productStatus} 
                    isFullyRegistered={isFullyRegistered} 
                    bidPrice={bidPrice}
                    bidderPrice={bidsMaxPrice[productId]} />
                )
            }
            {
                showBidForm && (
                    <PropertyPageBidForm 
                    isBidder={userType === 'bidder'}
                    stripePromise={stripePromise}
                    currentBidPrice={bidPrice}
                    handleBid={handleBid}
                    hasBid={bidsMaxPrice && bidsMaxPrice[productId]} 
                    isOutbid={isOutbid} 
                    isVerified={isVerified}
                    isFullyRegistered={isFullyRegistered}
                    isCardComplete={isCardComplete}
                    bidderStatus={bidderStatus}
                    bidLoading={bidLoading} />
                )
            }
            {
                showPlaceOfferMessage && (
                    <p className="font-medium my-3 p-3 text-center">To make an offer before the property goes live, please call our team on 01902 xxxxxx</p>
                )
            }
            {
                showLegalPack && (
                    <>
                        <div className="m-3">
                            <button className="flex justify-center items-center box-border w-full py-4 px-3 bg-light-gray" onClick={(e) => setDocsOpen((docsOpen) => !docsOpen)}>Legal Pack</button>
                        </div>
                        <ModalLayout 
                        open={docsOpen}
                        icon={<DescriptionIcon />}
                        withHeader
                        handleClose={() => setDocsOpen((docsOpen) => !docsOpen)}
                        title="Property Documents">
                            <DocumentView documents={assetDocuments} rootClass={`p-3 min-w-document-view ${!assetDocuments.length ? 'flex justify-center items-center' : ''} property-page__modal`} />
                        </ModalLayout>
                    </>
                )
            }
            {
                showHelpBidding && (
                    // <a href="#" className="flex justify-center items-center py-4 px-3 m-3 bg-light-gray" target="_blank" rel="noopener noreferrer">Help on Bidding</a>
                    <>
                        <div className="m-3">
                            <button className="flex justify-center items-center box-border w-full py-4 px-3 bg-light-gray" onClick={(e) => setHelpOpen((helpOpen) => !helpOpen)}>Help on Bidding</button>
                        </div>
                        <ModalLayout 
                        open={helpOpen}
                        icon={<HelpIcon />}
                        withHeader
                        handleClose={() => setHelpOpen((helpOpen) => !helpOpen)}
                        title="Help on Bidding">
                            <div className="p-3 min-w-document-view property-page__modal flex justify-center items-center">
                                <p>Placeholder text!</p>
                            </div>
                        </ModalLayout>
                    </>
                )
            }
            {
                showBidHistory && (
                    <div className="p-3">
                        <BidHistorySection isBidder={userType === 'bidder'} bidHistory={bidHistory} bidderAlias={bidderAlias} />
                    </div>
                )
            }
            
        </section>
    )
}