const USER_ACTIVE_IN_MIN = 10;

export const USER_INACTIVE_IN_MS = USER_ACTIVE_IN_MIN * 60 * 1000;

export const API_DOMAIN = 'https://app.spaceviva.com:9089/';

// Until images are hosted elsewhere besides main API link, we'll keep as API.
export const IMG_BASE_URL = API_DOMAIN;

export const BID_END_BUFFER = 7000;

//export const GOOGLE_MAP_API_KEY = null // replace null with the real key

export const GOOGLE_MAP_API_KEY = 'AIzaSyAW86lnv2XrApNXMLPczkhWB5cgOMCFnSo'

export const GOOGLE_RECAPTCHA_SITE_KEY = "6LeGqx8dAAAAALX7RfKDPTominDs-6RrvEWIYdqq" 

export const GOOGLE_ANALYTICS_TRACK_ID = 'G-7RQ275179K'