import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import AppContext from '@contexts/AppContext';

export default function Footer() {
    const { agencyData } = useContext(AppContext);

    return (
        <div className="bg-primary-gray">
            <footer className="mx-auto max-w-lg-container py-10 px-8 xxl:px-0">
                <div>
                    <h2 className="text-center md:text-left text-xl font-bold">Explore Auctions</h2>
                    <div className="flex flex-col items-center md:flex-row md:justify-between space-y-5 md:space-y-0 mt-8 font-semibold">
                        <div className="flex flex-col items-center md:items-start space-y-4 w-32">
                            <Link to="/buying">Buying</Link>
                            <Link to="/selling">Selling</Link>
                            <a href="https://app.spaceviva.com:9089/documents/Auction Buyer Guide.pdf" target="_blank" rel="noopener noreferrer">Buying Guide</a>
                            <a href="https://app.spaceviva.com:9089/documents/Auction Seller Guide.pdf" target="_blank" rel="noopener noreferrer">Selling Guide</a>
                        </div>
                        <div className=" flex flex-col items-center md:items-start space-y-4 w-28">
                            <Link to="/search?city=&minPrice=-1&maxPrice=-1&bedRooms=-1">Properties</Link>
                            <Link to="/faqs">FAQs</Link>
                            <Link to="/contact">Contact Us</Link>
                            <Link to="/">Privacy</Link>
                        </div>
                        <div className="flex flex-col items-center md:items-start space-y-4 w-32">
                            <Link to="/">Other services</Link>
                            <Link to="/">Cookie policy</Link>
                            <Link to="/">Terms of use</Link>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between items-center gap-4 mt-12">
                        <p className="flex-[2] font-bold">2021 Bartlams Limited. All rights reserved.</p>
                        <div className="flex-1 space-y-4 flex flex-col items-end">
                            <p className="text-center md:text-left font-bold">Powered by <span className="sr-only">{agencyData.name}</span></p>
                            <img src={agencyData.logoAddress} alt={`${agencyData.name} logo`} />
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}