import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTable, useSortBy, useFilters } from 'react-table';
import { parseISO, format } from 'date-fns';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import BidderDashboardTimeField from './BidderDashboardTimeField';

const getStatus = (productStatus, highestBidPrice, bidderPrice) => {
    if (productStatus === 'Live') {
        return bidderPrice === highestBidPrice ? 'Winning' : 'Out-Bid';
    }
    else if (productStatus.startsWith('Ended')) {
        return 'Ended';
    }
    else {
        if (bidderPrice === highestBidPrice) {
            return 'Won';
        }
        else {
            return 'Lost';
        }
    }
};

/**
 * @param sortStatus - ASC sort: 1, no sort: 0, DESC sort: -1
 */
const SortButtons = React.memo(({ sortStatus, handleClick }) => {
    return (
        <button className="inline-flex flex-col items-center justify-center cursor-pointer -mt-4" onClick={handleClick}>
            <ArrowDropUpIcon color={(!sortStatus || sortStatus === -1) ? 'disabled' : 'inherit'} />
            <ArrowDropDownIcon color={(!sortStatus || sortStatus === 1) ? 'disabled': 'inherit'} className="-m-4" />
        </button>
    )
}, (prevProps, nextProps) => prevProps.sortStatus === nextProps.sortStatus);

export default function BidderDashboardBidsTable({ biddedProperties, bidsMaxPrice, bidderAlias }) {
    const tableColumns = useMemo(() => [
        {
            Header: 'Title',
            accessor: 'title',
            width: 150,
        },
        {
            Header: 'Time remaining',
            width: 200,
            accessor: 'bidEndTime',
            sortType: 'time',
            sortDescFirst: true
        },
        {
            Header: 'Latest bid',
            width: 200,
            accessor: 'latestBid', 
        },
        {
            Header: 'Your Bid',
            width: 150,
            accessor: 'bidderPrice', 
        },
        {
            Header: 'Status',
            width: 150,
            accessor: 'status'
        },
    ], []);

    const tableData = useMemo(() => {
        return biddedProperties.map((item) => {
            const { productStatus, bidPrice, bidEndTime, title, productId, bidHistory = [], serverMS } = item
            const latestBidItem = bidHistory.filter(hist => hist.bidderAlias === bidderAlias).pop()
            const latestBidTime = latestBidItem?.time
            const latestBidTimestamp = latestBidTime ? parseISO(latestBidTime) : null
            const latestBid = latestBidTimestamp ? format(latestBidTimestamp, 'Pp') : 'N/A'
            return {
                title: <Link to={`/property/${productId}`} className="text-primary underline">{title}</Link>,
                bidEndTime: { 
                    timer: productStatus === 'Live' ? <BidderDashboardTimeField startServerMS={serverMS} bidEndTime={bidEndTime} /> : 'N/A (Property not live)', 
                    endTime: bidEndTime},
                latestBid,
                bidderPrice: `£ ${new Intl.NumberFormat('en-GB').format(bidsMaxPrice[productId])}`,
                status: getStatus(productStatus, bidPrice, bidsMaxPrice[productId])
            }
        })
    }, [biddedProperties, bidsMaxPrice, bidderAlias]);

    const sortTypes = useMemo(() => ({
        time: (rowA, rowB, columnId, desc) => {
            let dateA = parseISO(rowA.values[columnId].endTime).getTime();
            let dateB = parseISO(rowB.values[columnId].endTime).getTime();

            return dateA < dateB ? -1 : 1;
        }
    }), []);

    const propertyTable = useTable({ columns: tableColumns, data: tableData, disableMultiSort: true, sortTypes }, useFilters, useSortBy);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = propertyTable;

    const handleSort = (column) => {
        let isSortedDesc = column.isSortedDesc;

        if (isSortedDesc !== undefined) {
            if (isSortedDesc) {
                column.clearSortBy();
            }
            else {
                column.toggleSortBy(true);
            }
        }
        else {
            column.toggleSortBy(false);
        }
    }

    return (
        <table 
        className="bg-white rounded-lg shadow-lg mt-3 w-full"
        {...getTableProps()}>
            <thead className="border-b-2 border-table-light-blue">
            {
                headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                        headerGroup.headers.map((column) => {
                            return (
                                <th
                                    width={column.totalWidth}
                                    className={`relative border-r-2 border-table-light-blue p-3  pr-1 font-primary text-primary text-sm sm:text-base text-left`}
                                    {...column.getHeaderProps()}>
                                    <div className="flex justify-between items-center">
                                        {column.render('Header')}
                                        <div className="flex items-center">
                                            {column.canSort && <SortButtons sortStatus={column.isSortedDesc ? -1 : column.isSortedDesc === undefined ? 0 : 1} handleClick={(e) => handleSort(column)} />}
                                        </div>

                                    </div>

                                </th>
                            )
                        })
                    }
                    </tr>
                ))
            }
            </thead>
            <tbody
            className="p-6" 
            {...getTableBodyProps()}>
            {
                rows.map((row) => {
                    prepareRow(row);

                    return (
                        <tr 
                        className="border-b-2 border-table-light-blue font-primary text-sm sm:text-base"
                        {...row.getRowProps()}>
                        {
                            row.cells.map((cell) => {
                                return (
                                    <td
                                    className="p-3 border-r-2 border-table-light-blue" 
                                    {...cell.getCellProps()}>
                                        {cell.column.id === 'bidEndTime' ? cell.value.timer : cell.render('Cell')} 
                                    </td>
                                )
                            })
                        }
                        </tr>
                    )
                })
            }
            </tbody>
        </table>
    )
}
