import React from 'react';
import DescriptionIcon from '@material-ui/icons/Description';

import { API_DOMAIN } from '@util/site-constants';

export default function DocumentView({ documents, rootClass }) {
    return (
        <div className={rootClass}>
        {
            documents.length ? (
                <ul className="more-info-docs__grid grid gap-2">
                {
                    documents.map((doc) => (
                        <li>
                            <a className="flex flex-col items-center text-6xl hover:bg-table-light-blue p-5 rounded-lg" href={`${API_DOMAIN}/${doc.path}`} target="_blank" rel="noreferrer noopener">
                                <DescriptionIcon fontSize="inherit" />
                                <span className="font-primary font-semibold text-lg break-all">{doc.title}</span>
                            </a>
                        </li>
                    ))
                }
                </ul>
            )
            : (
                <p className="text-center font-primary font-semibold text-xl">No documents provided!</p>
            )
        } 
        </div>

    )
}