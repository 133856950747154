import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import Footer from '@components/Footer';

export default function BuyingPage() {
    return (
        <>
            <header className="max-w-[1200px] mx-auto mt-8">
                <div className="flex mx-8 space-x-12">
                    <div className="flex flex-col items-center lg:items-start flex-1 mt-12">
                        <h1 className="text-5xl text-center lg:text-left font-bold tracking-wide">Your next property awaits.</h1>
                        <ul className="space-y-10 mt-12 text-lg font-semibold tracking-wide">
                            <li className="flex space-x-3">
                                <svg className="flex-none" version="1.1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 512 512">
                                    <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
                                        c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
                                        C514.5,101.703,514.499,85.494,504.502,75.496z"/>
                                </svg>
                                <span>Auctions revolutionised. Properties of all types are now being bought online.</span>
                            </li>
                            <li className="flex space-x-3">
                                <svg className="flex-none" version="1.1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 512 512">
                                    <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
                                        c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
                                        C514.5,101.703,514.499,85.494,504.502,75.496z"/>
                                </svg>
                                <span>The modern method makes auctions more open to mortgage and first-time buyers.</span>
                            </li>
                            <li className="flex space-x-3">
                                <svg className="flex-none" version="1.1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 512 512">
                                    <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
                                        c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
                                        C514.5,101.703,514.499,85.494,504.502,75.496z"/>
                                </svg>
                                <span>Bid anytime, anywhere. Even from the comfort of your home.</span>
                            </li>
                        </ul>
                        <div className="flex flex-col xs:flex-row space-y-4 xs:space-y-0 xs:space-x-4 mt-12">
                            <Link to="/search?city=&minPrice=-1&maxPrice=-1&bedRooms=-1" className="px-4 py-2 rounded-full bg-secondary-agency text-white text-center tracking-wide font-bold">Browse properties</Link>
                            <Link to="/bidder/register" className="px-4 py-2 rounded-full bg-gray-100 text-gray-800 text-center tracking-wide font-bold">Register to bid</Link>
                        </div>
                    </div>
                    <div className="hidden lg:block flex-1 rounded-lg overflow-hidden">
                        <img src="/images/buying-hero.jpeg" alt="Child looking at building" />
                    </div>
                </div>
            </header>
            <main className="mt-36">
                <section className="max-w-lg-container mx-auto px-2 md:px-8">
                    <header className="text-center">
                        <h2 className="font-bold text-3xl">See your <span className="text-primary-agency">exclusive</span> benefits</h2>
                        <p className="mt-4 font-semibold">It couldn't be easier to buy property at auction.</p>
                    </header>
                    <ul className="buying-c-grid">
                        <li className="buying-c-grid-item">
                            <svg className="transform -translate-x-2" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                            viewBox="0 0 480.096 480.096" fill="var(--color-primary-agency)"> 
                                <path d="M370.032,178.808h-107.08L292.744,0l-182.68,258.808h108.704l-29.504,221.288L370.032,178.808z M140.944,242.808
                                    l124.512-176.4l-21.4,128.4h97.72L215.352,405.52l21.688-162.712H140.944z"/>
                            </svg>
                            <h3 className="buying-c-grid-item__heading">Complete faster</h3>
                            <p className="buying-c-grid-item__description">Complete faster with fixed 56-day timescales, meaning you know your moving date from the very outset.</p>
                        </li>
                        <li className="buying-c-grid-item">
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                            viewBox="0 0 480 480" fill="var(--color-primary-agency)">
                                <path d="M472,432h-24V280c-0.003-4.418-3.588-7.997-8.006-7.994c-2.607,0.002-5.05,1.274-6.546,3.41l-112,160
                                    c-2.532,3.621-1.649,8.609,1.972,11.14c1.343,0.939,2.941,1.443,4.58,1.444h104v24c0,4.418,3.582,8,8,8s8-3.582,8-8v-24h24
                                    c4.418,0,8-3.582,8-8S476.418,432,472,432z M432,432h-88.64L432,305.376V432z"/>
                                <path d="M328,464h-94.712l88.056-103.688c0.2-0.238,0.387-0.486,0.56-0.744c16.566-24.518,11.048-57.713-12.56-75.552
                                    c-28.705-20.625-68.695-14.074-89.319,14.631C212.204,309.532,207.998,322.597,208,336c0,4.418,3.582,8,8,8s8-3.582,8-8
                                    c-0.003-26.51,21.486-48.002,47.995-48.005c10.048-0.001,19.843,3.151,28.005,9.013c16.537,12.671,20.388,36.007,8.8,53.32
                                    l-98.896,116.496c-2.859,3.369-2.445,8.417,0.924,11.276c1.445,1.226,3.277,1.899,5.172,1.9h112c4.418,0,8-3.582,8-8
                                    S332.418,464,328,464z"/>
                                <path d="M216.176,424.152c0.167-4.415-3.278-8.129-7.693-8.296c-0.001,0-0.002,0-0.003,0
                                    C104.11,411.982,20.341,328.363,16.28,224H48c4.418,0,8-3.582,8-8s-3.582-8-8-8H16.28C20.283,103.821,103.82,20.287,208,16.288
                                    V40c0,4.418,3.582,8,8,8s8-3.582,8-8V16.288c102.754,3.974,185.686,85.34,191.616,188l-31.2-31.2
                                    c-3.178-3.07-8.242-2.982-11.312,0.196c-2.994,3.1-2.994,8.015,0,11.116l44.656,44.656c0.841,1.018,1.925,1.807,3.152,2.296
                                    c0.313,0.094,0.631,0.172,0.952,0.232c0.549,0.198,1.117,0.335,1.696,0.408c0.08,0,0.152,0,0.232,0c0.08,0,0.152,0,0.224,0
                                    c0.609-0.046,1.211-0.164,1.792-0.352c0.329-0.04,0.655-0.101,0.976-0.184c1.083-0.385,2.069-1.002,2.888-1.808l45.264-45.248
                                    c3.069-3.178,2.982-8.242-0.196-11.312c-3.1-2.994-8.015-2.994-11.116,0l-31.976,31.952
                                    C425.933,90.37,331.38,0.281,216.568,0.112C216.368,0.104,216.2,0,216,0s-0.368,0.104-0.568,0.112
                                    C96.582,0.275,0.275,96.582,0.112,215.432C0.112,215.632,0,215.8,0,216s0.104,0.368,0.112,0.568
                                    c0.199,115.917,91.939,210.97,207.776,215.28h0.296C212.483,431.847,216.013,428.448,216.176,424.152z"/>
                                <path d="M323.48,108.52c-3.124-3.123-8.188-3.123-11.312,0L226.2,194.48c-6.495-2.896-13.914-2.896-20.408,0l-40.704-40.704
                                    c-3.178-3.069-8.243-2.981-11.312,0.197c-2.994,3.1-2.994,8.015,0,11.115l40.624,40.624c-5.704,11.94-0.648,26.244,11.293,31.947
                                    c9.165,4.378,20.095,2.501,27.275-4.683c7.219-7.158,9.078-18.118,4.624-27.256l85.888-85.888
                                    C326.603,116.708,326.603,111.644,323.48,108.52z M221.658,221.654c-0.001,0.001-0.001,0.001-0.002,0.002
                                    c-3.164,3.025-8.148,3.025-11.312,0c-3.125-3.124-3.125-8.189-0.002-11.314c3.124-3.125,8.189-3.125,11.314-0.002
                                    C224.781,213.464,224.781,218.53,221.658,221.654z"/>
                            </svg>
                            <h3 className="buying-c-grid-item__heading">Bid anytime, anywhere</h3>
                            <p className="buying-c-grid-item__description">Begin your auction on any day with flexible durations.</p>
                        </li>
                        <li className="buying-c-grid-item">
                            <svg width="50" height="50" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 496 496"
                                fill="var(--color-primary-agency)">
                                <path d="M248,0C111.033,0,0,111.033,0,248s111.033,248,248,248s248-111.033,248-248C495.841,111.099,384.901,0.159,248,0z
                                        M248,480C119.87,480,16,376.13,16,248S119.87,16,248,16s232,103.87,232,232C479.859,376.072,376.072,479.859,248,480z"/>
                                <path d="M370.344,158.344L208,320.688l-82.344-82.344c-3.178-3.07-8.242-2.982-11.312,0.196c-2.994,3.1-2.994,8.015,0,11.116
                                    l88,88c3.124,3.123,8.188,3.123,11.312,0l168-168c3.07-3.178,2.982-8.242-0.196-11.312
                                    C378.359,155.35,373.444,155.35,370.344,158.344z"/>
                            </svg>
                            <h3 className="buying-c-grid-item__heading">Transparent</h3>
                            <p className="buying-c-grid-item__description">Reassurance of not being gazumped when entering into your Exclusive Reservation Period</p>
                        </li>
                        <li className="buying-c-grid-item">
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                            viewBox="0 0 480.011 480.011" fill="var(--color-primary-agency)">
                                <path d="M7.873,239.899h0.28c4.314,0.003,7.853-3.416,8-7.728C20.481,108.535,124.216,11.817,247.852,16.144
                                    c47.556,1.665,93.346,18.43,130.733,47.867h-42.576c-4.418,0-8,3.582-8,8s3.582,8,8,8h64h0.112
                                    c0.626-0.051,1.243-0.174,1.84-0.368c0.409-0.059,0.812-0.147,1.208-0.264c0.224-0.096,0.392-0.296,0.608-0.416
                                    c0.718-0.351,1.377-0.813,1.952-1.368l0.08-0.056c0.203-0.278,0.388-0.569,0.552-0.872c0.419-0.51,0.772-1.072,1.048-1.672
                                    c0.22-0.638,0.355-1.302,0.4-1.976c0.092-0.33,0.159-0.667,0.2-1.008v-64c0-4.418-3.582-8-8-8s-8,3.582-8,8v46.336
                                    C289.508-29.635,138.332-14.623,54.35,87.879C21.017,128.563,1.975,179.072,0.153,231.635
                                    C0.005,236.048,3.46,239.747,7.873,239.899z"/>
                                <path d="M72.641,388.883c-2.936-3.314-8.002-3.62-11.316-0.684c-3.314,2.936-3.62,8.002-0.684,11.316
                                    c5.507,6.18,11.325,12.076,17.432,17.664c3.321,2.915,8.375,2.585,11.29-0.735c2.832-3.227,2.612-8.113-0.498-11.073
                                    C83.179,400.156,77.764,394.653,72.641,388.883z"/>
                                <path d="M168.113,452.227c-7.314-2.476-14.494-5.331-21.512-8.552c-3.959-1.961-8.759-0.341-10.72,3.618
                                    c-1.961,3.959-0.341,8.759,3.618,10.72c0.136,0.067,0.274,0.131,0.414,0.19c7.524,3.464,15.226,6.529,23.072,9.184
                                    c4.186,1.416,8.728-0.83,10.144-5.016C174.546,458.185,172.3,453.643,168.113,452.227z"/>
                                <path d="M126.089,432.923v-0.008c-6.649-3.939-13.094-8.211-19.312-12.8c-3.469-2.737-8.499-2.143-11.236,1.326
                                    c-2.737,3.469-2.143,8.499,1.326,11.236c0.13,0.102,0.262,0.201,0.398,0.295c6.65,4.923,13.547,9.502,20.664,13.72
                                    c3.802,2.253,8.711,0.998,10.964-2.804C131.147,440.085,129.891,435.176,126.089,432.923z"/>
                                <path d="M58.153,370.827c-4.51-6.27-8.696-12.767-12.544-19.464c-2.209-3.833-7.107-5.149-10.94-2.94
                                    c-3.833,2.209-5.149,7.107-2.94,10.94c4.122,7.161,8.608,14.106,13.44,20.808c2.653,3.533,7.668,4.246,11.201,1.593
                                    C59.802,379.187,60.589,374.361,58.153,370.827z"/>
                                <path d="M35.839,334.017c0-1.115-0.234-2.218-0.685-3.237l-0.016-0.056c-3.131-7.052-5.895-14.262-8.28-21.6
                                    c-1.16-4.263-5.557-6.779-9.82-5.618c-4.263,1.16-6.779,5.557-5.618,9.82c0.068,0.248,0.147,0.493,0.238,0.734
                                    c2.553,7.881,5.512,15.625,8.864,23.2c1.283,2.896,4.153,4.762,7.32,4.76C32.26,342.018,35.84,338.435,35.839,334.017z"/>
                                <path d="M14.717,296.22c0.001,0,0.003-0.001,0.004-0.001v0c4.317-0.92,7.073-5.162,6.16-9.48
                                    c-1.594-7.557-2.801-15.191-3.616-22.872c-0.466-4.396-4.408-7.582-8.804-7.116s-7.582,4.408-7.116,8.804
                                    c0.876,8.227,2.173,16.403,3.888,24.496C6.149,294.374,10.395,297.135,14.717,296.22z"/>
                                <path d="M467.219,288.053c-0.003-0.001-0.006-0.001-0.009-0.002c-4.288-1.062-8.625,1.552-9.688,5.84
                                    c-1.854,7.5-4.094,14.899-6.712,22.168c-1.498,4.158,0.658,8.742,4.816,10.24c4.158,1.498,8.742-0.658,10.24-4.816
                                    c2.807-7.785,5.21-15.711,7.2-23.744C474.126,293.45,471.508,289.114,467.219,288.053z"/>
                                <path d="M213.217,462.411c-7.668-0.913-15.283-2.221-22.816-3.92c-4.31-0.974-8.594,1.73-9.568,6.04
                                    c-0.974,4.31,1.73,8.594,6.04,9.568c8.074,1.82,16.237,3.222,24.456,4.2c0.319,0.039,0.639,0.057,0.96,0.056
                                    c4.418-0.009,7.992-3.599,7.983-8.017C220.264,466.292,217.236,462.889,213.217,462.411z"/>
                                <path d="M452.466,333.683C452.466,333.683,452.466,333.683,452.466,333.683c-3.977-1.92-8.757-0.255-10.681,3.72
                                    c-3.363,6.954-7.085,13.729-11.152,20.296c-2.262,3.796-1.018,8.706,2.778,10.967c3.703,2.207,8.49,1.082,10.822-2.543
                                    c4.354-7.039,8.341-14.3,11.944-21.752C458.104,340.395,456.442,335.61,452.466,333.683z"/>
                                <path d="M428.831,375.29c-3.458-2.75-8.491-2.176-11.241,1.283c-0.048,0.06-0.095,0.121-0.141,0.182l0-0.016
                                    c-4.72,6.123-9.753,11.997-15.08,17.6c-3.046,3.203-2.919,8.27,0.284,11.316c3.203,3.046,8.27,2.919,11.316-0.284
                                    c5.696-6.003,11.085-12.291,16.144-18.84C432.863,383.073,432.289,378.04,428.831,375.29z"/>
                                <path d="M472.145,240.387c-4.346-0.222-8.05,3.122-8.271,7.468c-0.004,0.081-0.007,0.163-0.009,0.244
                                    c-0.271,7.72-0.942,15.421-2.008,23.072c-0.603,4.371,2.447,8.404,6.816,9.016c0.371,0.054,0.745,0.081,1.12,0.08
                                    c3.987-0.006,7.362-2.947,7.912-6.896c1.139-8.195,1.857-16.443,2.152-24.712C480.01,244.246,476.559,240.543,472.145,240.387z"
                                    />
                                <path d="M385.609,410.251v0.024c-5.888,5.026-12.029,9.747-18.4,14.144c-3.643,2.518-4.554,7.513-2.036,11.156
                                    c2.518,3.643,7.513,4.554,11.156,2.036c6.813-4.731,13.381-9.804,19.68-15.2c3.358-2.872,3.752-7.922,0.88-11.28
                                    C394.018,407.773,388.967,407.379,385.609,410.251z"/>
                                <path d="M304.897,454.475L304.897,454.475c-7.393,2.228-14.896,4.07-22.48,5.52c-4.339,0.835-7.18,5.028-6.345,9.367
                                    c0.723,3.761,4.011,6.482,7.841,6.489c0.507,0.002,1.013-0.044,1.512-0.136c8.13-1.554,16.173-3.532,24.096-5.928
                                    c4.196-1.383,6.477-5.906,5.094-10.102C313.281,455.637,309.007,453.346,304.897,454.475z"/>
                                <path d="M259.889,463.142c-0.147,0.008-0.293,0.021-0.439,0.037l0.04,0.032c-6.4,0.544-12.952,0.8-19.48,0.8h-3.656
                                    c-4.363,0.062-7.929,3.498-8.152,7.856c-0.08,4.418,3.436,8.064,7.853,8.144c0.001,0,0.002,0,0.003,0h3.952
                                    c6.992,0,14-0.312,20.8-0.896c4.411-0.254,7.781-4.036,7.526-8.447C268.081,466.257,264.299,462.887,259.889,463.142z"/>
                                <path d="M347.521,436.563h0.008c-6.773,3.709-13.731,7.068-20.848,10.064c-4.094,1.661-6.067,6.326-4.407,10.42
                                    s6.326,6.067,10.42,4.407c0.06-0.024,0.119-0.049,0.178-0.075c7.626-3.209,15.081-6.808,22.336-10.784
                                    c3.875-2.123,5.295-6.985,3.172-10.86C356.259,435.86,351.396,434.44,347.521,436.563z"/>
                                <path d="M240.009,72.011c-92.784,0-168,75.216-168,168s75.216,168,168,168s168-75.216,168-168
                                    C407.904,147.271,332.749,72.117,240.009,72.011z M368.009,248.011h23.592c-4.156,77.517-66.075,139.436-143.592,143.592v-23.592
                                    c0-4.418-3.582-8-8-8s-8,3.582-8,8v23.592c-77.517-4.156-139.436-66.075-143.592-143.592h23.592c4.418,0,8-3.582,8-8
                                    s-3.582-8-8-8H88.417c4.156-77.517,66.075-139.436,143.592-143.592v23.592c0,4.418,3.582,8,8,8s8-3.582,8-8V88.419
                                    c77.517,4.156,139.436,66.075,143.592,143.592h-23.592c-4.418,0-8,3.582-8,8S363.591,248.011,368.009,248.011z"/>
                                <path d="M248.009,217.483v-73.472c0-4.418-3.582-8-8-8s-8,3.582-8,8v73.472c-6.775,2.42-12.108,7.753-14.528,14.528h-41.472
                                    c-4.418,0-8,3.582-8,8s3.582,8,8,8h41.472c4.384,12.476,18.052,19.036,30.528,14.652c12.476-4.384,19.036-18.052,14.652-30.528
                                    C260.253,225.281,254.863,219.892,248.009,217.483z M240.009,248.011c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8
                                    S244.428,248.011,240.009,248.011z"/>
                            </svg>
                            <h3 className="buying-c-grid-item__heading">More time to arrange finance</h3>
                            <p className="buying-c-grid-item__description">Open to Mortgage-Buyers and First-Time Buyers as you have more time to arrange your method of payment.</p>
                        </li>
                        <li className="buying-c-grid-item">
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                            viewBox="0 0 480 480" fill="var(--color-primary-agency)">
                                <path d="M240,0C107.452,0,0,107.452,0,240s107.452,240,240,240s240-107.452,240-240C479.85,107.514,372.486,0.15,240,0z M240,464
                                    C116.288,464,16,363.712,16,240S116.288,16,240,16s224,100.288,224,224C463.859,363.653,363.653,463.859,240,464z"/>
                                <path d="M376,232H248V104c0-4.418-3.582-8-8-8s-8,3.582-8,8v128H104c-4.418,0-8,3.582-8,8s3.582,8,8,8h128v128
                                    c0,4.418,3.582,8,8,8s8-3.582,8-8V248h128c4.418,0,8-3.582,8-8S380.418,232,376,232z"/>
                            </svg>
                            <h3 className="buying-c-grid-item__heading">Expert help 7 days a week</h3>
                            <p className="buying-c-grid-item__description">Our friendly expert team guides you every step of the way.</p>
                        </li>
                        <li className="p-6 rounded-lg bg-primary-gray text-center">
                            <h3 className="font-bold text-xl">
                                Also selling? 
                                <br />
                                <span className="text-primary-agency">We make that easy, too</span>
                            </h3>
                            <p className="mt-3">
                                Set a reserve price to achieve your minimum asking price.
                            </p>
                            <HashLink to="/selling#how" className="inline-block mt-6 px-4 py-2 rounded-full bg-secondary-agency text-white font-bold">See how it works</HashLink>
                        </li>
                    </ul>
                </section>
                <section className="mt-32 mb-20">
                    <header className="text-center mx-4">
                        <h2 className="font-bold text-4xl">How to buy at auction</h2>
                        <p className="mt-4">Here's how we make your buying at auction easy, fast, and stress-free.</p>
                    </header>
                    <div className="bg-primary-gray px-4 py-12 mt-8">
                        <ol className="buying-c-steps max-w-lg-container mx-auto flex justify-center"> 
                            <li className="buying-c-step-item">
                                <div className="inline-flex flex-col items-center text-center">
                                    <img src="/images/home-process-img-1.png" alt="" aria-hidden="true" />
                                    <h3 className="mt-4 font-bold">Find Your Property</h3>
                                    <p className="mt-4 max-w-[320px] text-sm">
                                        Build your home search and discover listings you'll love. <Link to="/bidder/register">Register here</Link> for access to legal packs, 
                                        to book viewings on your schedule, and start bidding.
                                    </p>
                                </div>
                            </li>
                            <li className="buying-c-step-separator" aria-hidden="true">
                                •
                                <br />
                                •
                                <br />
                                •
                                <br />
                            </li>
                            <li className="buying-c-step-item">
                                <div className="inline-flex flex-col items-center text-center">
                                    <img src="/images/home-process-img-2.png" alt="" aria-hidden="true" />
                                    <h3 className="mt-4 font-bold">Place Bids</h3>
                                    <p className="mt-4 max-w-[320px] text-sm">
                                        Simply enter your bid amount, and 'Place Bid' to submit your bid in real time.
                                        To learn more about fees, view our Buying Guide.
                                    </p>
                                </div>
                            </li>
                            <li className="buying-c-step-separator" aria-hidden="true"> 
                                •
                                <br />
                                •
                                <br />
                                •
                                <br />
                            </li>
                            <li className="buying-c-step-item">
                                <div className="inline-flex flex-col items-center text-center">
                                    <img src="/images/home-process-img-3.png" alt="" aria-hidden="true" />
                                    <h3 className="mt-4 font-bold">Complete Your Purchase</h3>
                                    <p className="mt-4 max-w-[320px] text-sm">
                                        If you're the winning bidder, you enter into your exclusive reservation period where you have 28 days 
                                        exchange contracts, and a further 28 days to complete the purchase. We'll guide you every step of the way.
                                    </p>
                                </div>
                            </li>
                        </ol>
                    </div>
                </section>
                <section className="max-w-lg-container mx-auto px-4 mb-20">
                    <header className="text-center">
                        <h2 className="text-2xl md:text-4xl font-bold">Go-to resource for a successful sale</h2>
                        <p className="max-w-[1000px] mx-auto text-gray-600 font-semibold text-lg mt-8">
                            We strive to offer a transparent service to our sellers and buyers to ensure they are provided with enough
                            information to make an informed choice before proceeding.
                        </p>
                    </header>
                    <div className="flex justify-center mt-8">
                        <div>
                            <div className="flex flex-col sm:flex-row items-center space-y-8 sm:space-x-8 sm:space-y-0 text-center sm:text-left py-12 px-10 border-2 border-gray-300 rounded-lg">
                                <svg 
                                className="w-[100px] h-[100px] sm:w-[150px] sm:h-[150px]"
                                viewBox="-79 -16 533 533.33257" 
                                xmlns="http://www.w3.org/2000/svg"
                                fill="var(--color-primary-agency)">
                                    <path 
                                    d="m315.808594 33.949219h-80.355469c-9.105469-20.632813-29.535156-33.949219-52.089844-33.949219-22.558593 0-42.988281 13.316406-52.09375 33.949219h-80.359375c-30.3125.035156-54.875 24.601562-54.910156 54.914062v356.222657c.035156 30.3125 24.597656 54.878906 54.910156 54.914062h264.898438c30.3125-.035156 54.878906-24.601562 54.914062-54.914062v-356.222657c-.035156-30.3125-24.601562-54.878906-54.914062-54.914062zm-175.496094 25c5.867188 0 10.945312-4.082031 12.207031-9.820313 3.070313-13.976562 16.042969-24.128906 30.84375-24.128906s27.769531 10.152344 30.839844 24.128906c1.257813 5.738282 6.335937 9.820313 12.210937 9.820313h24.785157v10.96875c-.019531 16.511719-13.398438 29.890625-29.910157 29.910156h-75.855468c-16.515625-.019531-29.894532-13.398437-29.914063-29.910156v-10.96875zm205.410156 386.136719c-.015625 16.515624-13.398437 29.898437-29.914062 29.914062h-264.898438c-16.511718-.015625-29.890625-13.398438-29.910156-29.914062v-356.222657c.019531-16.515625 13.398438-29.894531 29.910156-29.914062h39.609375v10.96875c.035157 30.3125 24.601563 54.875 54.914063 54.910156h75.855468c30.3125-.035156 54.875-24.597656 54.910157-54.910156v-10.96875h39.609375c16.515625.019531 29.894531 13.398437 29.914062 29.914062zm0 0"/><path d="m245.226562 230.957031-82.0625 80.125-41.671874-40.675781c-4.902344-4.796875-12.753907-4.738281-17.59375.121094-4.835938 4.863281-4.855469 12.714844-.039063 17.597656l.167969.164062 50.398437 49.210938c4.859375 4.742188 12.613281 4.742188 17.46875 0l90.792969-88.648438c4.910156-4.828124 4.988281-12.714843.183594-17.640624-4.8125-4.925782-12.695313-5.035157-17.644532-.246094zm0 0"/>
                                </svg>
                                <div className="space-y-5">
                                    <h3 className="text-xl font-bold">
                                        Get acquainted with the process
                                    </h3>
                                    <p>As you begin buying, learn what to expect with our Buyers Guide.</p>
                                    <a href="https://app.spaceviva.com:9089/documents/Auction Buyer Guide.pdf" target="_blank" rel="noopener noreferrer" className="inline-block px-4 py-2 rounded-full bg-secondary-agency text-white font-semibold">Buying Guide</a>
                                </div>
                            </div>
                            <p className="mt-6 font-bold self-start text-center sm:text-left">
                                Have Questions? Find out more through our <Link className="text-primary underline" to="/faq">FAQs</Link> or <Link className="text-primary underline" to="/contact">Contact us</Link> - we're happy to help!
                            </p>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}